.plan_box{
    margin-top: 0;
    display: flex;
    /* margin-left: 20px; */
    padding: 20px;

}
.plan_card{
    max-width: 25rem;
    height: 40rem;
} 
.plan_header{
    background-color:#C11D6C !important;
    color: #fff;
}
.feature_list{
    list-style-type:square;
  }
  
.feature_list li::marker { 
    color: #C11D6C; 
}
.accordian_div{
    margin-top: -25px;
}
.offer_div {
    /* background: linear-gradient(
78deg,#ff0000 26%,#ff3481 78%); */
    /* background: linear-gradient( 
78deg,#e41e26 26%,#e48286 78%); */
    /* padding: 7px 5px;*/
    padding: 15px 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    position: absolute;
    /* top: -38px; */
    top:-48px;
    left: 0px; 
    z-index: 1;
    width: 100%;
    /*height: 50px;*/
    /* background-color: #D4AF37; */
    background-color: #DBA514;
}
.offer_div img {
    position: relative;
    top: -3px;
    margin-right: 5px;
}
.mobile-plan-cat-wrapper span.offer-text {
    font-size: 12px;
}
.thirty-pd-height span.offer-second-text, .mobile-plans-height span.offer-second-text {
    font-size: 10px;
    line-height: 1;
}
.mobile-plan-cat-wrapper a.abs-link {
    position: absolute;
    width: 100%;
    display: block;
    height: 100%;
    top: 0;
    left: 0;
}

.custom-offer
{
    background-color: #DBA514;
    padding: 0;
    border: 0;
}

.headerCollapse:focus
{

    color: #C11D6C !important;

}

.headerCollapse:hover
{
    color: #C11D6C !important;   
}

@media only screen and (max-width: 768px) {
    .plan_box{
        margin-top: 0; 
    
    }
  }