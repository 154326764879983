
.search-bar-container {
    max-width: 100%;
    margin: 10px;
    border-radius: 30px;
    border: 2px solid #C11D6C;
    margin-top: 10px;
  }

  .common-section {
    padding: 5px 0;
    margin: 20px;
  }

  .header-title p {
    text-align: justify;
  }

  .search-bar-container {
    max-width: 100%;
    margin: 10px;
    border-radius: 30px;
    border: 2px solid #C11D6C;
    margin-top: 10px;
  }

  .search-bar {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    /*overflow: hidden;*/
    border-radius: 30px;
    border-color: transparent;
    position: relative;
  }

  .search-input {
    flex: 1;
    border: none;
    padding: 20px;
    outline: none;
    border-radius: 30px;
  }

  .search-icon {
    color: #fff;
    padding: 10px;
    cursor: pointer;
  }

  
  .dropDown {
    position: absolute;
    background: #f8f8f8;
    right: 0;
    left: 0;
    z-index: 9999;
    top: 50px;
    list-style: none;
    border-radius: 15px;
    padding: 0;
    box-shadow: 0px 0px 12px -7px #000;
  }


  .dropDown li {
    padding: 10px 20px;
    border-bottom: 1px solid #ebebeb;
    font-size: 13px;
    cursor: pointer;
  }

  .dropDown li:last-child {
    border-bottom: none;
  }

  .dropDown li:hover {
    background: #ffffff;
  }
  
  .search-loader {
    display: block;
    padding: 40px 25px;
    box-shadow: 0px 2px 7px 1px #d7d2d2;
    border-radius: 5px;
    margin: 10px;
  }

  .button-57 {
    width: 100%;
  }

  .button-57 {
    position: relative;
    overflow: hidden;
    width: 245px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: #fff;
    display: inline-block;
    font-size: 15px;
    line-height: 15px;
    margin: 0 5px 5px 0;
    padding: 18px 18px 17px;
    text-decoration: none;
    cursor: pointer;
    background-color: #000;
    background: linear-gradient(90deg,#C11D6C 0%, rgba(14, 12, 9, 1) 100%);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: block;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 6px;
    line-height: 10px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background:#C11D6C;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(3.3deg) scaleY(2);
  }


.button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
  

  .search-content {
    padding: 20px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
  }
  
  .search-content h6 {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: rgb(1, 175, 1);
  }
  /*search-content h5,p*/
  .search-content-location{
    text-align: left;
    font-size: 22px;
    color: #777;
    padding-left: 5px;
  }
  
  .search-content h4{
    text-align: center;
    font-size: 22px;
    color: #777;
    padding-left: 5px;
  }
  
  .search-content-2 {
    padding: 20px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
  }
  
  .search-content-2 h6 {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #ff0000;
  }
  
  .search-content-2 h5 {
    text-align: center;
  }

  .anotherAddress {
    display: inline-block;
    padding: 7px 15px;
    font-size: 14px;
    text-align: center;
    width: 100%;
    cursor: pointer;
    color: #636363;
    transition: all .1s ease-in;
  }
  
  
  .anotherAddress:hover {
    letter-spacing: 1px;
  }
  
  
  .loaderDiv {
    text-align: center;
  }

  .search-loader p {
    font-size: 14px;
    color: #454545;
    margin-top: 15px;
    text-align: center;
};

/* modem card*/

.modem-table {
  margin: 50px 0;
}

.modem-bg {
  background: #e8e7e7;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 8px double #fff;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
}

.modem-bg h2 {
  font-size: 45px;
  color: #121820;
  background: #121820;
  background: -moz-linear-gradient(left,#C11D6C 0%, #000 100%) !important;
  background: -webkit-linear-gradient(left,#C11D6C 0%, #000 100%) !important;
  background: linear-gradient(to right,#C11D6C 0%, #000 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  margin-bottom: 5px;
}

.modem-bg h4 {
  font-size: 18px;
  font-weight: 500;
  margin-top: -5px;
  margin-bottom: 15px;
  color: #000 !important;
}

h3 {
  font-size: 23px;
  padding-top: 10px;
  font-weight: 700;
}

.modem-table {
  width: 80%;
  margin: 50px auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.modem-card {
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 40px;
  margin: 2px;
  box-shadow: rgba(60, 64, 67, 0.5) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  flex: 1;
  max-width: 100%;
  text-align: center;
}

.modem-card h2 {
  font-size: 40px;
  color: #000 !important;
  font-weight: 900;
}

.modem-card h2 span {
  font-size: 25px;
  color: #000 !important;
  font-weight: 800;
}

.modem-card p {
  color: #777;
  font-size: 14px;
  margin: 7px 0;
}

.modem-features h6 {
  text-align: left;
  width: 100%;
  color:#C11D6C;
  font-size: 18px;
  padding: 10px;
}

.modem-features li {
  color: #333;
  font-size: 16px;
  margin: 5px 0;
  text-align: left;
  padding-left: 10px;
}

.modem-data-pack {
  background-color: #000;
  padding: 10px 20px;
  margin-bottom: 20px !important;
  color: #fff;
  margin-top: -30px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.modem-card button {
  background: #C11D6C;
  background: linear-gradient(to left, #C11D6C, #000);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.modem-card button:hover {
  background:#C11D6C;
  background: linear-gradient(to left, #000,#C11D6C);
}

/* cart*/

.result-section {
  padding: 5px 0;
  margin: 20px;
}

.result-header-title {
  margin-bottom: 15px;
}

.result-header-title h2 {
  margin: 50px 0 -20px 20px;
  font-size: 38px;
  color: #fff;
}

.result-header-title p {
  margin: 30px 0 -20px 20px;
  color: #777;
  padding: 2px;
  text-align: left;
  font-size: 22px;
}

.table-dark {
  width: 80%;
  margin: 50px auto;
  border-collapse: collapse;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.table-dark th {
  padding: 30px;
  text-align: left;
  font-size: 30px;
}

.table-dark td {
  border: 1px solid #000;
  padding: 30px;
  text-align: left;
  font-size: 20px;
  font-family: 300;
}

.table-dark th {
  background-color:#C11D6C !important;
  color: #fff !important;
  text-align: right;
}

.table-dark tbody tr:nth-child(odd) {
  background-color: #0a0a0a;
}

.left-header,
.left-cell {
  text-align: left;
  font-size: 22px;
  padding: 30px;
  font-weight: 300;
}

.right-header,
.right-cell {
  text-align: right;
  padding: 30px;
}

.left-cell,
.right-cell {
  background-color: #000;
  font-size: 18px;
}

/* cards*/

.pricing-card {
  padding: 20px;
  margin: 10px 2px 10px 2px;
  max-width: 370px;
}

.pricing-table {
  margin: 50px 0;
}

.plan-bg {
  height: 193px;
  background:#C11D6C;
  /* background: linear-gradient(to left,#C11D6C, #000); */
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.plan-bg img {
  width: 50px;
  height: auto;
  border-radius: 50%;
  border: 3px solid #fff;
  padding: 2px;
  margin-top: -50px;
  background-color:#C11D6C;
}

.plan-bg h2 {
  font-size: 45px;
  color: #fff !important;
  margin-bottom: 5px;
}

.plan-bg h4 {
  font-size: 20px;
  font-weight: 900;
  margin-top: -5px;
  margin-bottom: 15px;
  color: #fff !important;
}

h3 {
  font-size: 28px;
  padding-top: 10px;
  font-weight: 500;
}

.pricing-table {
  width: 80%;
  margin: 50px auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.pricing-card {
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px;
  margin: 20px 2px 2px 2px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  flex: 1;
  max-width: 300px;
  text-align: center;
  height: 1179px;
}

.pricing-card h2 {
  font-size: 45px;
  color: #fff !important;
  font-weight: 900;
}

.pricing-card h3 {
  font-size: 26px;
  font-weight: 700;
}

.pricing-card p {
  color: #777;
  font-size: 17px;
  margin: 7px 0;
  text-align: center;
}
.pricing-card a {
  color: #777;
  text-decoration: none;
}

.pricing-card a:hover {
  color:#C11D6C;
  text-decoration: none;
}

.data-pack {
  background-color: #000;
  padding: 10px 20px;
  margin-bottom: 20px !important;
  text-transform: uppercase !important;
}

.data-pack {
  color: #fff;
  margin-top: -30px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

/* data-pack-description*/

.data-pack-description {
  height: 480px;
  padding: 5px;
  text-align: left;
  font-size: 13px;
}
.data-pack-description ul {
  list-style: none;
  padding: 0;
}
 
.data-pack-description ul li {
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;
}
 
.data-pack-description ul li:last-child {
  padding-bottom: 0;
}
 
.data-pack-description ul i {
  font-size: 20px;
  padding-right: 4px;
  color:#C11D6C;
  font-weight: 400;
}



.pricing-card button {
  background: #C11D6C;
  /* background: linear-gradient(to left,#C11D6C, #000); */
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.pricing-card button:hover {
  background:#C11D6C;
  background: linear-gradient(to left, #000,#C11D6C);
}


.get-plan {
  max-width: 800px;
  margin: 10px auto;
  position: relative;

}

.get-plan .title {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 2.0em;
  line-height: 32px;
  padding-bottom: 20px;
  color: #121820;
  background: #121820;
  background: -moz-linear-gradient(left,#C11D6C 0%, #000 100%) !important;
  background: -webkit-linear-gradient(left, #C11D6C 0%, #000 100%) !important;
  background: linear-gradient(to right,#C11D6C 0%, #000 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.get-plan p {
  color: #777;
  margin-top: -10px;
}

.get-plan span {
  color: #000;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}

.get-plan a {
  color:#C11D6C;
  font-weight: 600;
  text-decoration: none;
}

.contact-form .form-field {
  position: relative;
  margin: 22px 0;
}

.contact-form select {
  word-wrap: normal;
  width: 100%;
  height: 36px;
  padding: 2px;
  margin-left: -6px;
  border-width: 0 0 1px 0;
  border-color: #a3a3a3;
  line-height: 26px;
  color: #777;
  background-color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.contact-form .input-text {
  display: block;
  width: 100%;
  height: 36px;
  border-width: 0 0 1px 0;
  border-color: #777;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.contact-form .input-text2 {
  display: block;
  width: 100%;
  height: 36px;
  border-width: 0 0 1px 0;
  border-color: #C11D6C;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.contact-form .input-text:focus {
  outline: none;
}

.contact-form .input-text:focus+.label,
.contact-form .input-text.not-empty+.label {
  -webkit-transform: translateY(-24px);
  transform: translateY(-24px);
}

.contact-form .label {
  position: absolute;
  left: 14px;
  bottom: 32px;
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  color: #121820;
  cursor: text;
}

.contact-form .label2 {
  position: absolute;
  left: 14px;
  bottom: 32px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #C11D6C;
  cursor: text;
}

.contact-form .submit-btn {
  display: inline-block;
  background-color: #000;
  background-image: linear-gradient(125deg,#C11D6C, #000);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 15px;
  padding: 8px 16px;
  border: none;
  width: 100%;
  cursor: pointer;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.contact-form .submit-btn:hover {
  background-color: #000;
  background-image: linear-gradient(125deg,#C11D6C, #000);
}

.contact-form .back-btn {
  display: inline-block;
  background-color: #000;
  background-image: linear-gradient(125deg, #000,#C11D6C);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 15px;
  padding: 8px 16px;
  border: none;
  width: 100%;
  cursor: pointer;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.contact-form .back-btn:hover {
  background-color: #000;
  background-image: linear-gradient(125deg, #000,#C11D6C);
}

/* new date picker*/
.newDatePicker{
  display: block !important; 
  justify-content:left; 
 width: 100%;
}

.newDatePicker{
  display: inline-block !important;
}

#dropdown-date{
  display: inline-block !important;
  width: 100%;
  text-align: left;
}

.newDatePicker #dropdown-year{
    display: inline-block;
    margin-right: 8px;
    /* width: 58px !important; */
    width: 62px !important;
    border-color: #a3a3a3!important;
    border: 0 0 2px 0!important;
}

.newDatePicker #dropdown-month{
    display: inline-block;
    /* width: 100px !important; */
    width: 109px !important;
    margin-right: 8px;
}

.newDatePicker #dropdown-day{
    display: inline-block;
    width: 42px !important;
}

.newDatePicker #dropdown-date select{
  border-color: #a3a3a3!important;
  border: 0 0 2px 0!important;
  margin-left: 0;
}

.check-box {
  display: block;
  position: relative;
  text-align: left;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.tick {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #d7d5d5;
}

.check-box:hover input~.tick {
  background-color: #ccc;
}

.check-box input:checked~.tick {
  background-color:#C11D6C;
}

.tick:after {
  content: "";
  position: absolute;
  display: none;
}

.check-box input:checked~.tick:after {
  display: block;
}

.check-box .tick:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.modem-card {
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 40px;
  margin: 2px;
  box-shadow: rgba(60, 64, 67, 0.5) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  flex: 1;
  max-width: 100%;
  text-align: center;
}

/* payment*/



.tabcontent {
  display: block;
  border-top: none;
  border: 2px double #ccc;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 40px;
  margin: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.check-box {
  display: block;
  position: relative;
  text-align: left;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.tick {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #d7d5d5;
}

.check-box:hover input~.tick {
  background-color: #ccc;
}

.check-box input:checked~.tick {
  background-color:#C11D6C;
}

.tick:after {
  content: "";
  position: absolute;
  display: none;
}

.check-box input:checked~.tick:after {
  display: block;
}

.check-box .tick:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}



.payment-submit-btn {
  display: inline-block;
  background-color: #000;
  background-image: linear-gradient(125deg,#C11D6C, #000);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 15px;
  padding: 8px 16px;
  border: none;
  width: 100%;
  cursor: pointer;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.payment-submit-btn:hover {
  background-color: #000;
  background-image: linear-gradient(125deg,#C11D6C, #000);
}

.payment-back-btn {
  display: inline-block;
  background-color: #000;
  background-image: linear-gradient(125deg, #000,#C11D6C);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 15px;
  padding: 8px 16px;
  border: none;
  width: 100%;
  cursor: pointer;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.payment-back-btn:hover {
  background-color: #000;
  background-image: linear-gradient(125deg, #000,#C11D6C);
}

.thanks-section {
  margin: 10px 2px;
  padding: 30px;
  background-color: #e8e7e7;
  border: 12px double #fff;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
};


@media only screen and (min-device-width: 361px) and (max-device-width: 479px) and (orientation: portrait) {
  .modem-card {
      padding: 15px !important;
      margin: 10px 0 !important;
  }
}

/* @media (min-device-width: 361px)   {
  .modem-card {
    padding: 15px !important;
    margin: 10px 0 !important;
}
} */




@media (max-width: 768px) {
  .modem-card {
      width: 100%;
      margin: 20px 0;
      padding: 0px !important
  }
}

@media (max-width: 768px) {
    .modem-card {
        margin: 20px 0;
    }
}


@media screen and (max-width: 600px) {
  .tabcontent {
      padding: 2px;
      margin: 2px;
  }
}



@media (max-width: 768px){
.prfActivationDate {
    text-align: left;
    margin-top: 50px !important;
}

}

@media only screen and (min-device-width: 250px) and (max-device-width: 360px) and (orientation: portrait){
.modem-card {
    padding: 2px;
    margin: 5px 0;
}
}


@media (min-width: 992px){
.col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
}
}

/* Special Offer - After 5th Recharge */

.special-offer {
	/* background: #7f0015; */
  background: url('../media/offer-img/background.png') no-repeat center right;
	width: 99%;
	height: 85px;
	border-radius: 15px;
	padding: 50px;
	margin: -35px 10px 15px 10px;
  }

  .offer-title {
    /* margin-top: -12px; */
    margin-top: -23px;
    color: #fff;
  }

  .offer-details {
    margin-top: -7px;
    color: #e4e4e4;
    font-size: 10px;
  }

  .circle-container {
    display: inline-flex;
  }

  .circle {
    height: 50px;
    width: 50px;
    margin-top: -15px;
    margin-right: -50px;
  }
  .circle h3 {
    text-align: center;
    margin-top: -7px;
  }
  .circle2 {
    opacity: 0.5;
    height: 50px!important;
    width: 50px!important;
    margin-top: -15px!important;
    margin-right: 30px;
  }
  .offer-circle {
    height: 50px;
    width: 50px;
    margin-top: -30px;
    margin-left: -35px 
  }
  .number-select{
    margin-top: -35px;
    margin-bottom: 6px;
  }

.top-container{
  padding-left: 15px;
  padding-right: 20px;
}











  @media only screen and (min-device-width: 320px) and (max-device-width: 341px) and (orientation: portrait) {
    .special-offer {
      width: 95%;
      height: 220px;
      margin-top: 10px;
    }
    .circle {
      height: 35px;
      width: 35px;
      margin-top: 20px;
      margin-right: -40px;
      z-index: 10;
    }
    .circle2 {
      height: 35px !important;
      width: 35px !important;
      margin-top: 20px !important;
      /* margin-right: 6px !important; */
      margin-right: 2px !important;
    }
    .offer-circle {
      height: 40px;
      width: 40px;
      /* margin-top: -2px; */
      margin-top: -11px;
      margin-left: -18px;
  }
  .top-container {
    padding-left: 2px;
    padding-right: 2px;
  }
}
  @media only screen and (min-device-width: 342px) and (max-device-width: 383px) and (orientation: portrait) {
    .special-offer {
      margin-top: 10px;
      width: 95%;
      height: 225px;
    }
    .circle {
      height: 40px;
      width: 40px;
      margin-top: 20px;
      margin-right: -45px;
      z-index: 10;
    }
    .circle2 {
      height: 40px !important;
      width: 40px !important;
      margin-top: 20px !important;
      margin-right: 5px !important;
    }
    .offer-circle {
      height: 40px;
      width: 40px;
      /* margin-top: -2px;
      margin-left: -10px; */
      margin-top: -11px;
    margin-left: -29px;
  }
  .top-container {
    padding-left: 2px;
    padding-right: 2px;
  }
}
@media only screen and (min-device-width: 384px) and (max-device-width: 393px) and (orientation: portrait) {
  .special-offer {
    width: 95%;
    height: 210px;
    margin-top: 10px;
  }
  .circle {
    height: 40px;
    width: 40px;
    margin-top: 20px;
    margin-right: -45px;
    z-index: 10;
  }
  .circle2 {
    height: 40px !important;
    width: 40px !important;
    margin-top: 20px !important;
    margin-right: 8px !important;
  }
  .offer-circle {
    height: 40px;
    width: 40px;
    margin-top: -2px;
    margin-left: -12px;
}
.top-container {
  padding-left: 2px;
  padding-right: 2px;
}
}
@media only screen and (min-device-width: 394px) and (max-device-width: 479px) and (orientation: portrait) {
  .special-offer {
    margin-top: 10px;
    width: 95%;
    height: 185px;
  }
  .circle {
    height: 40px;
    width: 40px;
    margin-top: 20px;
    margin-right: -45px;
    z-index: 10;
  }
  .circle2 {
    height: 40px;
    width: 40px;
    margin-top: 15px !important;
    margin-right: 2px !important;
  }
  .offer-circle {
    height: 40px;
    width: 40px;
    margin-top: -2px;
    margin-left: -12px;
}
.top-container {
  padding-left: 2px;
  padding-right: 2px;
}
}
@media only screen and (min-device-width: 480px) and (max-device-width: 599px) {
  .special-offer {
    width: 95%;
    height: 215px;
    margin-top: 10px;
  }
  .circle {
    height: 40px;
    width: 40px;
    margin-top: 20px;
    margin-right: -45px;
    z-index: 10;
  }
  .circle2 {
    height: 40px;
    width: 40px;
    margin-top: 15px !important;
    /* margin-right: 20px !important; */
    margin-right: 11px !important;
  }
  .offer-circle {
    height: 40px;
    width: 40px;
    /* margin-top: -2px; */
    margin-top: -10px ;
    margin-left: -32px;
  }
  .top-container {
    padding-left: 2px;
    padding-right: 2px;
  }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 692px) {
  .special-offer {
    width: 95%;
    height: 195px;
    margin-top: 10px;
  }
  .circle {
    height: 50px;
    width: 50px;
    margin-top: 20px;
    margin-right: -45px;
    z-index: 10;
  }
  .circle2 {
    height: 50px;
    width: 50px;
    margin-top: 17px !important;
    margin-right: 30px !important;
  }
  .offer-circle {
    height: 40px;
    width: 40px;
    margin-top: -2px;
    margin-left: -40px;
}
.top-container {
  padding-left: 2px;
  padding-right: 2px;
}
}
@media only screen and (min-device-width: 693px) and (max-device-width: 767px) {
  .special-offer {
    width: 97%;
    height: 190px;
    margin-top: 10px;
  }
  .circle {
    height: 50px;
    width: 50px;
    margin-top: 8px;
    margin-right: -56px;
    z-index: 10;
  }
  .circle2 {
    height: 40px;
    width: 40px;
    margin-top: 8px !important;
    margin-right: 55px !important;
  }
  .offer-circle {
    height: 65px;
    width: 65px;
    margin-top: -30px;
    margin-left: -65px;
}
.top-container {
  padding-left: 2px;
  padding-right: 2px;
}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 853px) {
  .special-offer {
    width: 98%;
    height: 250px;
    /* margin-bottom: 15px; */
    margin-bottom: 110px ;
    margin-top: 10px;
  }
  .circle {
    height: 60px;
    width: 60px;
    margin-top: 18px;
    margin-right: -55px;
    z-index: 10;
}
  .circle2 {
    height: 55px !important;
    width: 55px !important;
    margin-top: 22px !important;
    margin-right: 38px !important;
  }
  .offer-circle {
    height: 60px;
    width: 60px;
    margin-top: -7px;
    margin-left: -45px;
  }
  .number-select {
    margin-top: 59px;
    margin-bottom: 8px;
    padding: 0 50px;
}
.top-container {
  padding-left: 2px;
  padding-right: 2px;
}
}
@media only screen and (min-device-width: 854px) and (max-device-width: 991px) {
  .special-offer {
    width: 98%;
    height: 190px;
    margin-top: 10px;
  }
  .circle {
    height: 50px;
    width: 50px;
    margin-top: -6px;
    margin-right: -46px;
    z-index: 10;
  }
  .circle2 {
    height: 40px;
    width: 40px;
    margin-top: -8px !important;
    margin-right: 10px !important;
  }
  .offer-circle {
    height: 40px;
    width: 40px;
    margin-top: -18px;
    margin-left: -18px;
  }
  .number-select {
    padding: 0 50px;
    margin-top: 58px;
    margin-bottom: 0px;
}
.top-container {
  padding-left: 2px;
  padding-right: 2px;
}
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1200px) {
  .special-offer {
    width: 98%;
    height: 139px;
    margin-bottom: 17px;
    margin-top: 10px;
  }
  .circle {
    height: 40px;
    width: 40px;
    margin-top: -12px;
    margin-right: -45px;
    z-index: 10;
  }
  .circle2 {
    height: 40px;
    width: 40px;
    margin-top: -17px !important;
    margin-right: 10px !important;
  }
  .offer-circle {
    height: 40px;
    width: 40px;
    margin-top: -35px;
    margin-left: -20px;
  }
  .number-select {
    margin-top: -25px;
    margin-bottom: 28px;
    padding-right: 35px;
}

}



/* @media only screen and (min-device-width: 992px) and (max-device-width: 1500px) {
  .custom-select{
    margin-top: 46px !important;
    min-width: 300px !important;
    margin-left:-100px !important;

}

} */


@media only screen and (min-device-width: 992px) and (max-device-width: 1500px){
  .custom-select {
      width: max-content !important;
      min-width: 191px !important;
      margin-left: -23px !important;
  }
  }






  @media only screen and  (max-device-width:768px){
    .custom-select {
        width: max-content !important;
        min-width: 191px !important;
    }
    }





.email-custom{
  margin-left: 0px !important;
   position: 'fixed' !important;
  text-align: start !important;
}






@media only screen and (min-device-width:768px) and (max-device-width:768px) {
  .number-select {
    margin-top: -29px;
    margin-bottom: 8px;
    padding: 0 50px;
}

}


@media only screen and (min-device-width:768px) and ((max-width: 1024px)) {
  .kt-menu__link-text{
    display: none !important;
  }

}





/* @media only screen and (min-device-width:1808px) and ((max-width: 1808px)) {
  .form-select{
    margin-top:-4px !important;
    margin-bottom: 6px;
}

} */


.number-select {
  margin-top: 5px !important;
  margin-bottom: 6px;
}



/* @media only screen and (min-device-width:767px) and ((max-width: 1038px)) {
  .kt_selectpicker{
    margin-top: 81px !important;
  }

} */

 
 @media only screen and (min-device-width:1807px) and ((max-width:1808px)) {
  .selector{
    margin-top:-40px ;
  }

}  

.selector{
  margin-top:-40px 
}

.top-container{
  min-height: 50px;

}



@media only screen and (min-device-width: 854px) and (max-device-width: 991px){
  .num-picker{
    margin-top: 115px !important;


  }
}  


@media only screen and (min-device-width: 992px) and (max-device-width: 1500px){
.refer-select{
    margin-left:0px !important;
}
}



@media only screen and (min-device-width: 200px) and (max-device-width: 714px){
  .nbn-service-section{
      margin-left:auto !important;
      margin-right: auto !important;
  }
  }


  @media (min-width:200px) and (max-width:350px) {
    .pricing-card {
      margin: 0 !important;
    }

    .table-dark th {
      font-size: 10px !important;
    }

    .newDatePicker #dropdown-year {
      display: inline-block;
      margin-right: 8px;
      width: 50px !important;
      border-color: #a3a3a3 !important;
      border: 0 0 2px 0 !important;
    }

    .newDatePicker #dropdown-month {
      display: inline-block;
      width: 60px !important;
      margin-right: 8px;
    }

    table th {
      font-size: 12px;
    }
    .table-dark td {
      border: 1px solid #000;
      padding: 30px;
      text-align: left;
      font-size: 10px;
      font-family: 300;
  }

  }













  /*  aug offer css  */

  .strikethrough {
    text-decoration: line-through;
    opacity: 0.8; /* Reduce the clarity */
    font-size: 1.8rem; /* Reduce the font size */
    padding-right: 8px;
}


.custom-paragraph {
  float: right; 
  background-color:white;
   color:#C11D6C!important; 
   font-size: 14px !important;
    font-weight: 600; 
    padding: 2px 5px;
     /* border-top-left-radius: 10px; 
     border-bottom-right-radius: 10px;  */
     border-bottom-left-radius: 8px;
     border-bottom-right-radius: 8px;
     margin-top:-10px !important; 
     width: 78px;
      margin-right:-9px !important; 
     /* margin: 0px !important; */
}


@media (min-width:200px) and (max-width:490px) {
  .pricing-card h2 {
    font-size: 26px;
    color: #fff !important;
    font-weight: 900;
}

}





.discount-amount{
  padding-top: 30px;
}
 .strike-amount{
  margin-top: -15px;
} 

@media (min-width:1180px) and (max-width:1180px)  {
  .pricing-card {
    height: 1362px;
}
.pricing-card button{
  margin-top: 59px;
} 

}



@media (min-width:1024px) and (max-width:1024px)  {
  .pricing-card {
    height: 1362px;
}
.pricing-card button{
  margin-top: 59px;
} 

}



@media (min-width:320px) and (max-width:320px)  {
  .pricing-card {
    margin:5px !important
}


}





@media (min-width:1138px) and (max-width:1138px)  {
  .pricing-card {
    height: 1482px
}
.pricing-card button{
  margin-top:89px
} 
.pricing-card h2 {
  font-size: 33px;
}

}






/* line number 502 please,remove this line also */
/* .pricing-card {
  height: 1179px;
} */
/* line number 453 please,remove this line also */

/* .plan-bg {
  height: 138px;
} */







.popular-flag{
  margin-left: 72px;
}

.popular-strike{
  padding-left: 69px !important;
}



@media (min-width:200px) and (max-width:562px) {
.popular-strike{
  padding-left: 64px !important;
}
.custom-paragraph {
  font-size: 9px !important;
  width:64px !important;

}
} 



@media (min-width:562px) and (max-width:748px) {
.popular-strike{
  padding-left: 17px !important;
}
.custom-paragraph {
  font-size: 9px !important;
  width:64px !important;

}
} 





/* @media (min-width:1244px) and (max-width:1440px) {
  .popular-strike{
    padding-left: 59px !important;
  }

  }  */



@media (min-width:488px) and (max-width:488px) {
.popular-strike{
  padding-left: 17px !important;
}
.custom-paragraph {
  font-size: 9px !important;
  width:64px !important;

}
} 





@media (min-width:1024px) and (max-width:1180px) {
  .popular-strike {
    padding-left: 20px !important;
  }

  .custom-paragraph {
    font-size: 9px !important;
    width: 55px !important
}

  .popular-flag {
    margin-left: 29px;

}
}




@media (min-width:1366px) and (max-width:1366px) {
  .popular-strike {
    padding-left:51px!important
  }

  .custom-paragraph {
    font-size: 9px !important;
    width: 55px !important
}

  .popular-flag {
    margin-left: 29px;

}
.pricing-card{
  height: 1249px;

}

}



.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
  display: flex !important;
}


.swal-button--danger {
  background-color:#C11D6C!important;
}




.swal-icon--warning {
  border-color: #C11D6C !important;
}

.swal-icon--warning__body, .swal-icon--warning__dot {
  background-color: #C11D6C!important;
}


.swal-button {
  background-color: #C11D6C!important;
}


.swal-icon--error {
    border-color: #C11D6C !important;
   
}

.swal-icon--error__line {
 
  background-color: #C11D6C!important;

}


.swal-button--cancel {
  color: white !important;
}



.border-danger {
  border-color: #C11D6C !important;
}


.swal-icon--success {
  border-color: #C11D6C !important;
}



.swal-icon--success__line {
  background-color: #C11D6C !important;
}


.swal-icon--success__ring {
  border: 4px solid #C11D6C !important;
}


.bg-danger {
  background-color: #C11D6C  !important;
}


.form-control:disabled {
  background-color:#fcf2f7 !important;
  opacity: 1;
}


.thanks-icon {
  color: #C11D6C;
  font-size: 60px;
}


.form-control[readonly] {
  background-color:#fcf2f7 !important;
  opacity: 1;
}

.resendButtonStyle {
  background: grey !important;
  border-color: #e9e9e9 !important;
  color: white !important;
}




  /* *************** */