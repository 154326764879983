body {
  padding: 0 !important; }

.kt-login-v2 {
  background-color: #fff; }
  .kt-login-v2 .kt-login-v2__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding: 2rem 3rem; }
    .kt-login-v2 .kt-login-v2__head .kt-login-v2__logo > a {
      display: inline-block; }
    .kt-login-v2 .kt-login-v2__head .kt-login-v2__signup {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .kt-login-v2 .kt-login-v2__head .kt-login-v2__signup > span {
        margin-bottom: 0;
        color: #9D9FB1;
        font-size: 1.1rem;
        padding-right: 0.5rem;
        font-weight: 500; }
      .kt-login-v2 .kt-login-v2__head .kt-login-v2__signup > a {
        font-weight: 500;
        font-size: 1.1rem; }
  .kt-login-v2 .kt-login-v2__body {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 3rem; }
    .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper {
      width: 50%;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container {
        width: 100%;
        max-width: 500px;
        -webkit-box-shadow: 0px 0px 80px 0px rgba(77, 84, 124, 0.09);
        box-shadow: 0px 0px 80px 0px rgba(77, 84, 124, 0.09);
        border-radius: 0.6rem; }
        .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__title {
          text-align: center; }
          .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__title > h3 {
            color: #636576;
            margin-top: 3rem;
            font-size: 1.5rem; }
        .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__form {
          padding: 3rem 4rem 2rem 4rem; }
          .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__form .form-control {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
            padding-left: 0; }
        .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__actions {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 100%;
          padding: 0;
          margin-top: 3rem; }
          .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__actions a {
            display: inline-block; }
            .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__actions a span {
              font-size: 1rem;
              font-weight: 500; }
          .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__actions .btn {
            padding: 1rem 3rem;
            color: #fff;
            font-size: 1.2rem;
            font-weight: 500; }
        .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__desc {
          text-align: center;
          color: #A5A7BB;
          font-size: 1.2rem;
          font-weight: 400; }
        .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__options {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding: 2rem 4rem 3rem 4rem; }
          .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__options > a {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            font-weight: 400;
            font-size: 1rem;
            padding: 1rem 0;
            color: #fff; }
            .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__options > a > i {
              font-size: 1.2rem;
              color: rgba(255, 255, 255, 0.5);
              padding-right: 0.4rem; }
            .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__options > a:not(:first-child):not(:last-child) {
              margin: 0 2rem; }
            .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__options > a.btn-facebook {
              background-color: #3b5998; }
              .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__options > a.btn-facebook:hover {
                -webkit-transition: background-color 0.3s ease;
                transition: background-color 0.3s ease;
                background-color: #324c82; }
            .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__options > a.btn-twitter {
              background-color: #1da1f2; }
              .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__options > a.btn-twitter:hover {
                -webkit-transition: background-color 0.3s ease;
                transition: background-color 0.3s ease;
                background-color: #0d92e3; }
            .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__options > a.btn-google {
              background-color: #ea4335; }
              .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__options > a.btn-google:hover {
                -webkit-transition: background-color 0.3s ease;
                transition: background-color 0.3s ease;
                background-color: #e72919; }
    .kt-login-v2 .kt-login-v2__body .kt-login-v2__image {
      width: 50%;
      margin-left: 8rem;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .kt-login-v2 .kt-login-v2__body .kt-login-v2__image > img {
        width: 100%;
        max-width: 500px; }
  .kt-login-v2 .kt-login-v2__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 2rem 3rem; }
    .kt-login-v2 .kt-login-v2__footer .kt-login-v2__info > a {
      font-weight: 500;
      font-size: 1rem;
      color: #A5A7BB; }
    .kt-login-v2 .kt-login-v2__footer .kt-login-v2__link {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2; }
      .kt-login-v2 .kt-login-v2__footer .kt-login-v2__link > a {
        color: #9C9FB1;
        font-weight: 500;
        font-size: 1rem; }
        .kt-login-v2 .kt-login-v2__footer .kt-login-v2__link > a:not(:first-child):not(:last-child) {
          margin: 0 2rem; }

@media (max-width: 1024px) {
  .kt-login-v2 .kt-login-v2__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.5rem 2rem; }
    .kt-login-v2 .kt-login-v2__head .kt-login-v2__logo {
      margin-bottom: 2rem; }
    .kt-login-v2 .kt-login-v2__head .kt-login-v2__signup > span {
      padding-left: 0; }
  .kt-login-v2 .kt-login-v2__body {
    padding-top: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
    .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper {
      padding-left: 0;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container {
        margin-left: 0; }
        .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__title {
          padding-top: 1rem; }
        .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__form {
          padding: 3rem 2rem; }
        .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__actions {
          padding: 0; }
        .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__options {
          margin: 1rem 0;
          padding: 2rem 2rem; }
          .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__options > a > i {
            padding: 0.4rem 0.3rem 0.4rem 0; }
          .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container .kt-login-v2__options > a:not(:first-child):not(:last-child) {
            margin: 0 2rem; }
    .kt-login-v2 .kt-login-v2__body .kt-login-v2__image {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 100%;
      margin-left: 0;
      padding: 4rem 0; }
      .kt-login-v2 .kt-login-v2__body .kt-login-v2__image > img {
        width: 100%;
        max-width: 500px;
        height: 100%; }
  .kt-login-v2 .kt-login-v2__footer {
    padding: 1.5rem 2rem; }
    .kt-login-v2 .kt-login-v2__footer .kt-login-v2__info > a {
      padding-right: 0.5rem; }
    .kt-login-v2 .kt-login-v2__footer .kt-login-v2__link > a:not(:first-child):not(:last-child) {
      margin: 0 1rem; } }
