.kt-invoice-1 {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
  .kt-invoice-1 .kt-invoice__container {
    width: 100%;
    margin: 0;
    padding: 0 30px; }
  .kt-invoice-1 .kt-invoice__head {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 8rem 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-size: cover;
    background-position: center; }
    .kt-invoice-1 .kt-invoice__head .kt-invoice__brand {
      margin-bottom: 5rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.11); }
      .kt-invoice-1 .kt-invoice__head .kt-invoice__brand .kt-invoice__title {
        font-size: 5.5rem;
        font-weight: 700;
        margin-bottom: 5rem;
        color: #ffffff;
        text-align: center; }
      .kt-invoice-1 .kt-invoice__head .kt-invoice__brand .kt-invoice__logo {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-bottom: 5rem;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .kt-invoice-1 .kt-invoice__head .kt-invoice__brand .kt-invoice__logo img {
          display: block;
          width: 9rem;
          margin-right: 2rem; }
        .kt-invoice-1 .kt-invoice__head .kt-invoice__brand .kt-invoice__logo .kt-invoice__desc {
          color: #aaabf7;
          font-size: 1.25rem;
          font-weight: 500;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column; }
    .kt-invoice-1 .kt-invoice__head .kt-invoice__items {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .kt-invoice-1 .kt-invoice__head .kt-invoice__items .kt-invoice__item .kt-invoice__subtitle {
        font-weight: 700;
        font-size: 1.25rem;
        color: #ffffff;
        margin-bottom: 1.25rem; }
      .kt-invoice-1 .kt-invoice__head .kt-invoice__items .kt-invoice__item .kt-invoice__desc {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        color: #aaabf7;
        font-size: 1.25rem;
        font-weight: 500;
        padding-top: 1.5rem; }
      .kt-invoice-1 .kt-invoice__head .kt-invoice__items .kt-invoice__item .kt-invoice__text {
        color: #aaabf7;
        font-size: 1.25rem;
        font-weight: 500; }
      .kt-invoice-1 .kt-invoice__head .kt-invoice__items .kt-invoice__item .kt-invoice__info .kt-invoice__text {
        float: right;
        padding-left: 4rem; }
      .kt-invoice-1 .kt-invoice__head .kt-invoice__items .kt-invoice__item .kt-invoice__info:last-child {
        padding-top: 1.7rem; }
      .kt-invoice-1 .kt-invoice__head .kt-invoice__items .kt-invoice__item:first-child {
        margin-right: 1rem; }
  .kt-invoice-1 .kt-invoice__body {
    padding: 4rem 0; }
    .kt-invoice-1 .kt-invoice__body table {
      background-color: transparent; }
      .kt-invoice-1 .kt-invoice__body table thead tr th {
        background-color: transparent;
        padding: 1rem 1rem 0.5rem 0;
        border-top: none;
        color: #595d6e;
        border-bottom: 1px solid #eaebec !important;
        font-weight: 500; }
        .kt-invoice-1 .kt-invoice__body table thead tr th:not(:first-child) {
          text-align: right; }
        .kt-invoice-1 .kt-invoice__body table thead tr th:last-child {
          padding-right: 0; }
      .kt-invoice-1 .kt-invoice__body table tbody tr td {
        background-color: transparent;
        padding: .25rem 1rem .25rem 0;
        border-top: none;
        font-weight: 700;
        font-size: 1.1rem;
        color: #595d6e; }
        .kt-invoice-1 .kt-invoice__body table tbody tr td:not(:first-child) {
          text-align: right; }
        .kt-invoice-1 .kt-invoice__body table tbody tr td:last-child {
          color: #C11D6C;
          font-size: 1.2rem;
          padding-right: 0; }
      .kt-invoice-1 .kt-invoice__body table tbody tr:first-child td {
        padding-top: 1.8rem; }
  .kt-invoice-1 .kt-invoice__footer {
    padding: 3rem 0; }
    .kt-invoice-1 .kt-invoice__footer .kt-invoice__container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
    .kt-invoice-1 .kt-invoice__footer .kt-invoice__bank {
      margin-right: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .kt-invoice-1 .kt-invoice__footer .kt-invoice__bank .kt-invoice__title {
        font-size: 1.2rem;
        text-transform: capitalize;
        font-weight: 600;
        color: #74788d; }
      .kt-invoice-1 .kt-invoice__footer .kt-invoice__bank .kt-invoice__item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 10px; }
        .kt-invoice-1 .kt-invoice__footer .kt-invoice__bank .kt-invoice__item .kt-invoice__label {
          font-size: 1.1rem;
          font-weight: 500;
          margin-right: 40px;
          color: #595d6e;
          text-align: left; }
        .kt-invoice-1 .kt-invoice__footer .kt-invoice__bank .kt-invoice__item .kt-invoice__value {
          font-size: 1.1rem;
          font-weight: 400;
          color: #74788d;
          text-align: right; }
        .kt-invoice-1 .kt-invoice__footer .kt-invoice__bank .kt-invoice__item:first-child {
          margin-right: 1rem; }
    .kt-invoice-1 .kt-invoice__footer .kt-invoice__total {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: right; }
      .kt-invoice-1 .kt-invoice__footer .kt-invoice__total .kt-invoice__title {
        font-size: 1.2rem;
        text-transform: capitalize;
        font-weight: 600;
        color: #74788d; }
      .kt-invoice-1 .kt-invoice__footer .kt-invoice__total .kt-invoice__price {
        color: #C11D6C;
        font-weight: 700;
        font-size: 30px; }
      .kt-invoice-1 .kt-invoice__footer .kt-invoice__total .kt-invoice__notice {
        font-size: 1rem;
        font-weight: 500;
        color: #74788d; }
  .kt-invoice-1 .kt-invoice__actions {
    padding: 2rem 0;
    /*background-color: #f7f8fa;*/ }
    .kt-invoice-1 .kt-invoice__actions .kt-invoice__container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }

@media (min-width: 1025px) {
  .kt-invoice-1 .kt-invoice__container {
    width: 80%;
    margin: 0 auto; } }

@media (max-width: 1024px) {
  .kt-invoice-1 .kt-invoice__head {
    padding: 3rem 0; }
    .kt-invoice-1 .kt-invoice__head .kt-invoice__container {
      width: 100%;
      margin: 0;
      padding: 40px 20px; }
    .kt-invoice-1 .kt-invoice__head .kt-invoice__brand .kt-invoice__title {
      font-size: 4rem; }
    .kt-invoice-1 .kt-invoice__head .kt-invoice__items .kt-invoice__item:last-child {
      padding-top: 2rem; }
  .kt-invoice-1 .kt-invoice__body {
    padding: 30px 20px; }
  .kt-invoice-1 .kt-invoice__footer {
    padding: 20px; }
    .kt-invoice-1 .kt-invoice__footer .kt-invoice__container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
    .kt-invoice-1 .kt-invoice__footer .kt-invoice__bank {
      margin-right: 0; }
      .kt-invoice-1 .kt-invoice__footer .kt-invoice__bank .kt-invoice__item .kt-invoice__label {
        margin-right: 20px; }
    .kt-invoice-1 .kt-invoice__footer .kt-invoice__total {
      margin-top: 30px;
      text-align: left; }
  .kt-invoice-1 .kt-invoice__actions {
    padding: 2rem 20px; }
    .kt-invoice-1 .kt-invoice__actions .kt-invoice__container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; } }

@media print {
  .kt-header,
  .kt-header-mobile,
  .kt-aside,
  .kt-aside-secondary,
  .kt-footer,
  .kt-subheader,
  .kt-scrolltop,
  .kt-quick-panel,
  .kt-demo-panel,
  .kt-sticky-toolbar {
    display: none !important; }
  body,
  .kt-wrapper,
  .kt-body,
  .kt-content {
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important; }
  .kt-invoice-1 {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .kt-invoice-1 .kt-invoice__head {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
      .kt-invoice-1 .kt-invoice__head .kt-invoice__container {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
    .kt-invoice-1 .kt-invoice__actions {
      display: none !important; }
    .kt-invoice-1 .kt-invoice__container {
      width: 100%;
      padding: 0 10px; } }
