@import url("//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css");
 .login-block{
     background: #C11D6C;
    /* fallback for old browsers */
     background: -webkit-linear-gradient(to bottom, #C11D6C, #C11D6C);
    /* Chrome 10-25, Safari 5.1-6 */
     background: linear-gradient(to bottom, #C11D6C, #C11D6C);

      display: flex;
      align-items: center;

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ 
     width:100%;
     padding : 50px;
}
 .banner-sec{
    /* background:url(https://static.pexels.com/photos/33972/pexels-photo.jpg) no-repeat left bottom; */
     background-size:cover;
     /* min-height:500px; */
     border-radius: 0 10px 10px 0;
     padding:0;
}
 .container{
    background:#fff;
     border-radius: 10px;
     /* box-shadow:15px 20px 0px rgba(0,0,0,0.1); */
}
 .carousel-inner{
    border-radius:0 10px 10px 0;
    height: 100%x;
}
 .carousel-caption{
    text-align:left;
     left:5%;
}
 .login-sec{
    padding: 50px 30px;
     position:relative;
}
 .login-sec .copy-text{
    position:absolute;
     width:80%;
     bottom:20px;
     font-size:13px;
     text-align:center;
}
 .login-sec .copy-text i{
    color:#FEB58A;
}
 .login-sec .copy-text a{
    color:#E36262;
}
 .login-sec h2{
    margin-bottom:30px;
     font-weight:800;
     font-size:30px;
     color: #C11D6C;
}
 .login-sec h2:after{
    content:" ";
     width:100px;
     height:5px;
     background:#FEB58A;
     display:block;
     margin-top:20px;
     border-radius:3px;
     margin-left:auto;
    margin-right:auto
}
 .btn-login{
    background: #C11D6C;
     color:#fff;
     font-weight:600;
}
 .banner-text{
    width:70%;
     position:absolute;
     bottom:40px;
     padding-left:20px;
}
 .banner-text h2{
    color:#fff;
     font-weight:600;
}
 .banner-text h2:after{
    content:" ";
     width:100px;
     height:5px;
     background:#FFF;
     display:block;
     margin-top:20px;
     border-radius:3px;
}
 .banner-text p{
    color:#fff;
}
.carousel-item img{
    height :auto;
    object-fit: cover;
}
@media only screen and (max-width: 768px) {
    .carousel-item img{
        height :auto; 
    }
}
.btn-signin{
   margin-left: 78%;
    margin-top: -20%;

}




.displayPayConfirm{
    padding: 40px 15px;
    margin-bottom: 80px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.displayPayConfirm .confirmPayText{
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 25px;
            color: #434343;
}


.displayPayConfirm button{
           

    padding: 10px 30px;
    min-width: 75px;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    border-radius: 5px;
    border: none;

}


.displayPayConfirm .confirmPayYes{
           
    background: #C11D6C;
    margin-right: 15px;
    color: #fff;
}

.displayPayConfirm .confirmPayYes:hover{
           
    background: #C11D6C;
}



.displayPayConfirm .confirmPayNo{
           
    background: #c8c8c8;
    color: #3d3d3d;
}

.displayPayConfirm .confirmPayNo:hover{
           
    background: #e1e0e0;
}



@media (max-width: 768px){
   .displayPayConfirm{
         border: none !important; padding: 0 !important; margin-bottom: 40px !important;
   }
}