.user-icon{
  width: 80px;
  height: auto;
}
/*--------------------------------------------------------------
# Pricing Table 
--------------------------------------------------------------*/
/* .nbn-pricing-table {
  margin: 50px 0;
} */

.nbn-plan-bg {
  background:#C11D6C;
  /* background: linear-gradient(to left,#C11D6C, #000); */
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.nbn-plan-bg img {
  width: 50px;
  height: auto;
  border-radius: 50%;
  border: 3px solid #fff;
  padding: 2px;
  margin-top: -50px;
  background-color: #C11D6C;
}

.nbn-plan-bg h2 {
  font-size: 45px;
  color: #fff !important;
  margin-bottom: 5px;
}

.nbn-plan-bg h4 {
  font-size: 20px;
  font-weight: 900;
  margin-top: -5px;
  margin-bottom: 15px;
  color: #fff !important;
}

h3 {
  font-size: 28px;
  padding-top: 10px;
  font-weight: 500;
}

.nbn-pricing-table {
  width: 80%;
  margin: 50px auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.nbn-pricing-table td,th{
  padding: 15px;
  font-size: 16px;
}

.nbn-pricing-card {
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px;
  margin: 20px 2px 2px 2px;
  margin-left: 50px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  flex: 1;
  max-width: 300px;
  text-align: center;
}

.nbn-pricing-card h2 {
  font-size: 45px;
  color: #fff !important;
  font-weight: 900;
}

.nbn-pricing-card h3 {
  font-size: 26px;
  font-weight: 700;
}

.nbn-pricing-card p {
  color: #777;
  font-size: 17px;
  margin: 7px 0;
  text-align: center;
}

.nbn-pricing-card a {
  color: #777;
  text-decoration: none;
}

.nbn-pricing-card a:hover {
  color:#C11D6C;
  text-decoration: none;
}


.nbn-data-pack {
  background-color: #000;
  padding: 10px 20px;
  margin-bottom: 20px !important;
}

.nbn-data-pack {
  color: #fff;
  margin-top: -10px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.nbn-pricing-card button {
  background: #C11D6C;
  /* background: linear-gradient(to left,#C11D6C, #000); */
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.nbn-pricing-card button:hover {
  background: #C11D6C;
  background: linear-gradient(to left, #000,#C11D6C);
}


/*--------------------------------------------------------------
# NBN My Account Section
--------------------------------------------------------------*/
 
.nbn-my-account {
  margin: 10px auto;
  padding: 20px;
}
 
.nbn-my-account .nbn-my-service{
  margin: 0;
  padding: 5px;
  background-color: #C11D6C;
  color: #fff;
  text-align: right;
  border-top-left-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
 
.nbn-my-service h3{
  text-align: left;
  padding: 10px;
  margin: 5px 0 0 5px;
  font-size: 22px;
}
.nbn-my-service  span{
  text-align: right;
  padding: 5px;
  margin-top: 4px;
  font-size: 18px;
  font-weight: 800;
}
.nbn-my-service strong{
  text-align: right;
  padding: 10px;
  font-size: 18px;
}
.nbn-my-account .nbn-my-service p{
  padding: 5px;
  font-size: 13px;
  margin-bottom: 0px;
}
 
.nbn-data-section{
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-bottom-right-radius: 20px;
  padding-top: 2px;
}
 
.nbn-data-content{
  padding: 2px;
  margin: 0;
}
.nbn-data-content p{
  text-align: left;
  margin-bottom: 0;
  font-size: 14px;
}
.nbn-data-content .nbn-data-unlimited{
  font-weight: 500;
  font-size: 15px;
}
.nbn-data-content strong{
  text-align: left;
  font-size: 20px!important;
}
.nbn-data-section2{
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.nbn-data-section img{
  width: 60px;
  height: auto;
  padding: 2px;
}
.nbn-data-section2 img{
  width: 60px;
  height: auto;
  padding: 2px;
}
.nbn-data-table{
  padding: 0;
  margin: 0;
}
.nbn-progress {
  width: 180px;
  height: 8px;
  margin-top: 5px;
  background-color: #ddd;
}

.nbn-skills {
  text-align: right;
  padding-top: 0px;
  padding-bottom: 0px;
  color: white;
  width: var(--progress-width);
  background-color: #C11D6C;
}
.nbn-para-content{
  margin: 0;
  padding: 0;
  font-size: 14px;
}
 

.nbn-box-content{
  text-align: center;
  top: 50%;
  left: 50%;
  padding: 20px 10px;
  margin: 10px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
}
 
.nbn-drop-btn{
  background-color: #000;
  width: 100%;
  background-color: #C11D6C;
}

.btn-data {
  border: rgb(230, 9, 9) 2px solid;
  background-color: #fff;
  color: rgb(230, 9, 9);
  font-size: 16px;
  padding: 5px 20px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  border: rgb(230, 9, 9) 2px solid;
  text-align: center;
  font-weight: 500;
  box-shadow: none;
  margin-top: 10px;
}

.btn-data:hover {
  background-color: rgb(230, 9, 9);
  border: rgb(230, 9, 9) 2px solid;
  color: white;
}


/*--------------------------------------------------------------
# Media Queries 
--------------------------------------------------------------*/

@media only screen and (min-device-width: 250px) and (max-device-width: 480px) and (orientation: portrait) {
  .nbn-my-service h3 {
    text-align: center;
  }
  .nbn-my-service p, span {
    /* margin-top: -12px; */
    font-size: 14px;
  }
  .nbn-my-service p, span {
    text-align: center;
    /* margin-top: -12px; */
    font-size: 14px;
  }
  .nbn-data-section img {
    width: 80px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-section2 img {
    width: 80px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-content {
    text-align: center;
    padding: 5px;
  }
  .nbn-data-content p {
    text-align: center;
  }
  .nbn-progress {
    width: 70px;
  }
  .nbn-para-content {
    padding: 0 20px;
  }
  .nbn-service-section {
    width: 90% !important;
    padding: 2px;
  }
}

@media only screen and (min-device-width: 361px) and (max-device-width: 479px) and (orientation: portrait) {
  .nbn-my-service h3 {
    text-align: center;
  }
  .nbn-my-service p, span {
    text-align: center;
    /* margin-top: -12px; */
    font-size: 15px;
  }
  .nbn-data-section img {
    width: 80px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-section2 img {
    width: 80px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-content {
    text-align: center;
    padding: 5px;
  }
  .nbn-data-content p {
    text-align: center;
  }
  .nbn-progress {
    width: 70px;
  }
  .nbn-para-content {
    padding: 0 20px;
  }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 600px) and (orientation: portrait) {
  .nbn-my-service h3 {
    font-size: 32px;
    text-align: center;
}
  .nbn-my-service p, span {
    text-align: center;
    margin-top: -12px;
    font-size: 20px!important;
  }
  .nbn-data-section img {
    width: 135px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-section2 img {
    width: 135px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-content {
    text-align: center;
    padding: 5px;
  }
  .nbn-data-content p {
    text-align: center;
    font-size: 18px;
  }
  .nbn-data-content strong {
    text-align: left;
    font-size: 30px !important;
  }
  .nbn-data-content .nbn-data-unlimited {
    font-size: 22px;
  }
  .nbn-progress {
    width: 170px;
  }
  .nbn-para-content {
    padding: 0 20px;
  }
  .btn-data{
    font-size: 22px;
    width: 50%;
    margin-bottom: 20px;
  }
}

@media only screen and (min-device-width: 601px) and (max-device-width: 692px) and (orientation: portrait) {
  .nbn-my-service h3 {
    font-size: 32px;
    text-align: center;
}
  .nbn-my-service p, span {
    text-align: center;
    margin-top: -12px;
    font-size: 20px!important;
  }
  .nbn-data-section img {
    width: 135px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-section2 img {
    width: 135px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-content {
    text-align: center;
    padding: 5px;
  }
  .nbn-data-content p {
    text-align: center;
    font-size: 18px;
  }
  .nbn-data-content strong {
    text-align: left;
    font-size: 30px !important;
  }
  .nbn-data-content .nbn-data-unlimited {
    font-size: 22px;
  }
  .nbn-progress {
    width: 180px;
  }
  .nbn-para-content {
    padding: 0 20px;
  }
  .btn-data{
    font-size: 22px;
    width: 50%;
    margin-bottom: 20px;
  }
}

@media only screen and (min-device-width: 693px) and (max-device-width: 767px) and (orientation: portrait) {
  .nbn-my-service h3 {
    font-size: 32px;
    text-align: center;
}
  .nbn-my-service p, span {
    text-align: center;
    margin-top: -12px;
    font-size: 20px!important;
  }
  .nbn-data-section img {
    width: 135px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-section2 img {
    width: 135px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-content {
    text-align: center;
    padding: 5px;
  }
  .nbn-data-content p {
    text-align: center;
    font-size: 18px;
  }
  .nbn-data-content strong {
    text-align: left;
    font-size: 30px !important;
  }
  .nbn-data-content .nbn-data-unlimited {
    font-size: 22px;
  }
  .nbn-progress {
    width: 170px;
  }
  .nbn-para-content {
    padding: 0 20px;
  }
  .btn-data{
    font-size: 22px;
    width: 50%;
    margin-bottom: 20px;
  }
  .nbn-data-content label{
    font-size: 16px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 853px) and (orientation: portrait) {
  .nbn-my-service h3 {
    font-size: 20px;
  }
  .nbn-my-service p, span {
    text-align: right;
    margin-top: 10px;
    font-size: 18px;
  }
  .nbn-data-section img {
    width: 50px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-section{
    padding: 5px;
  }
  .nbn-data-section2{
    padding: 5px;
  }
  .nbn-data-section2 img {
    width: 50px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-content {
    text-align: center;
    padding: 5px;
  }
  .nbn-data-content p {
    text-align: center;
    padding: 2px;
    font-size: 18px;
  }
  .nbn-data-content .nbn-data-unlimited {
    font-weight: 500;
    font-size: 12px;
}
  .nbn-data-content strong {
    text-align: left!important;
    font-size: 24px!important;
  }
  .nbn-progress {
    width: 50px;
  }
  .nbn-para-content {
    padding: 0;
    font-size: 12px;
  }
  .btn-data{
    font-size: 12px;
    padding: 8px;
  }
}

@media only screen and (min-device-width: 854px) and (max-device-width: 991px) and (orientation: portrait) {
  .nbn-my-service h3 {
    font-size: 24px;
  }
  .nbn-my-service p, span {
    text-align: center;
    margin-top: 10px;
    font-size: 18px;
  }
  .nbn-data-section img {
    width: 50px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-section{
    padding: 5px;
  }
  .nbn-data-section2{
    padding: 5px;
  }
  .nbn-data-section2 img {
    width: 50px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-content {
    text-align: center;
    padding: 5px;
  }
  .nbn-data-content p {
    text-align: center;
    padding: 2px;
    font-size: 16px;
  }
  .nbn-data-content .nbn-data-unlimited {
    font-weight: 500;
    font-size: 12px;
}
  .nbn-data-content strong {
    text-align: center!important;
    font-size: 14px!important;
  }
  .nbn-progress {
    width: 50px;
  }
  .nbn-para-content {
    padding: 0;
    font-size: 12px;
  }
  .btn-data{
    font-size: 12px;
    padding: 8px;
  }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1200px) and (orientation: portrait) {
  .user-icon{
    width: 50px;
    height: auto;
  }
}

@media only screen and (min-device-width: 1280px) and (max-device-width: 1366px) and (orientation: portrait) {

}

@media only screen and (min-device-width: 250px) and (max-device-width: 480px) and (orientation: landscape) {
  .nbn-my-service h3 {
    text-align: center;
    font-size: 26px;
  }
  .nbn-my-service p, span {
    /* margin-top: -12px; */
    font-size: 14px;
  }
  .nbn-my-service p, span {
    text-align: center;
    /* margin-top: -12px; */
    font-size: 14px;
  }
  .nbn-data-section img {
    width: 80px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-section2 img {
    width: 80px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-content {
    text-align: center;
    padding: 5px;
  }
  .nbn-data-content p {
    text-align: center;
  }
  .nbn-progress {
    width: 70px;
  }
  .nbn-para-content {
    padding: 0 20px;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 760px) and (orientation: landscape) {
  .nbn-my-service h3 {
    text-align: center;
    font-size: 26px;
  }
  .nbn-my-service p, span {
    margin-top: -12px;
    font-size: 14px;
  }
  .nbn-my-service p, span {
    text-align: center;
    margin-top: -12px;
    font-size: 14px;
  }
  .nbn-data-section img {
    width: 80px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-section2 img {
    width: 80px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-content {
    text-align: center;
    padding: 5px;
  }
  .nbn-data-content p {
    text-align: center;
  }
  .nbn-progress {
    width: 170px;
  }
  .nbn-para-content {
    padding: 0 20px;
  }
  .btn-data{
    margin-bottom: 30px;
  }
  .nbn-service-section {
    width: 260px !important;
  }
}

@media only screen and (min-device-width: 761px) and (max-device-width: 998px) and (orientation: landscape) {
  .nbn-my-service h3 {
    font-size: 20px;
  }
  .nbn-my-service p, span {
    text-align: right;
    margin-top: 10px;
    font-size: 14px;
  }
  .nbn-data-section img {
    width: 50px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-section2 img {
    width: 50px;
    height: auto;
    padding: 0px;
  }
  .nbn-data-content {
    text-align: left;
    padding: 5px;
  }
  .nbn-data-content p {
    text-align: left;
    padding: 2px;
    font-size: 12px;
  }
  .nbn-data-content .nbn-data-unlimited {
    font-weight: 500;
    font-size: 12px;
}
  .nbn-data-content strong {
    text-align: left!important;
    font-size: 14px!important;
  }
  .nbn-progress {
    width: 50px;
  }
  .nbn-para-content {
    padding: 0;
    font-size: 12px;
  }
  .btn-data{
    font-size: 12px;
    padding: 8px;
  }
}

@media only screen and (min-device-width: 999px) and (max-device-width: 1292px) and (orientation: landscape) {
  .nbn-data-section{
    padding: 10px;
  }
  .nbn-data-section2{
    padding: 10px;
  }
  .user-icon{
    width: 40px;
    height: auto;
  }
  .nbn-service-section {
    width: 260px !important;
  }
  
  .skills {
    text-align: right; /* Right-align text */
    padding-top: 0px; /* Add top padding */
    padding-bottom: 0px; /* Add bottom padding */
    color: white; /* White text color */
  }
  /* .progress-bar1 {width: 5%; background-color: #C11D6C;}
  .progress-bar2 {width: 10%; background-color: #C11D6C;}
  .progress-bar3 {width: 15%; background-color: #C11D6C;}
  .progress-bar4 {width: 20%; background-color: #C11D6C;}
  .progress-bar5 {width: 25%; background-color: #C11D6C;}
  .progress-bar6 {width: 30%; background-color: #C11D6C;}
  .progress-bar7 {width: 35%; background-color: #C11D6C;}
  .progress-bar8 {width: 40%; background-color: #C11D6C;}
  .progress-bar9 {width: 45%; background-color: #C11D6C;}
  .progress-bar10 {width: 50%; background-color: #C11D6C;}
  .progress-bar11 {width: 55%; background-color: #C11D6C;}
  .progress-bar12 {width: 60%; background-color: #C11D6C;}
  .progress-bar13 {width: 65%; background-color: #C11D6C;}
  .progress-bar14 {width: 70%; background-color: #C11D6C;}
  .progress-bar15 {width: 75%; background-color: #C11D6C;}
  .progress-bar16 {width: 80%; background-color: #C11D6C;}
  .progress-bar17 {width: 85%; background-color: #C11D6C;}
  .progress-bar18 {width: 90%; background-color: #C11D6C;}
  .progress-bar19 {width: 95%; background-color: #C11D6C;}
  .progress-bar20 {width: 100%; background-color: #C11D6C;} */


  
  
  
}
