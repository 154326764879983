.kt-wizard-v3 .kt-wizard-v3__nav {
  padding: 4rem;
  border-bottom: 1px solid #eeeef4; }
  @media (max-width: 1399px) {
    .kt-wizard-v3 .kt-wizard-v3__nav {
      padding: 2rem 1rem; } }
  .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-line {
    height: 3px;
    background-color: #f6f6f9;
    -webkit-transform: translateY(1.7rem);
    transform: translateY(1.7rem);
    margin: 0 10%;
    position: relative;
    z-index: 0; }
    @media (max-width: 576px) {
      .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-line {
        display: none; } }
  .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    z-index: 1; }
    @media (max-width: 576px) {
      .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column; } }
    .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      flex-basis: auto;
      font-weight: 500;
      font-size: 1.1rem; }
      @media (max-width: 576px) {
        .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          margin-bottom: 2rem; } }
      .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item span {
        width: 3.5rem;
        height: 3.5rem;
        font-size: 1.5rem;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 1rem; }
      .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item i {
        display: none;
        width: 3.5rem;
        height: 3.5rem;
        font-size: 1.1rem;
        border-radius: 50%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 1rem; }
      .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item .kt-wizard-v3__nav-label {
        margin-top: 1rem;
        color: #74788d;
        text-align: center; }
        @media (max-width: 576px) {
          .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item .kt-wizard-v3__nav-label {
            text-align: left;
            margin-top: 0; } }
      .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item[data-ktwizard-state="current"] {
        color: #5d78ff; }
        .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item[data-ktwizard-state="current"] span {
          background-color: #5d78ff;
          color: #ffffff; }
      .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item[data-ktwizard-state="pending"] {
        color: #a2a5b9; }
        .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item[data-ktwizard-state="pending"] span {
          background-color: #f6f6f9;
          color: #a2a5b9; }
      .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item[data-ktwizard-state="done"] {
        color: #1dc9b7; }
        .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item[data-ktwizard-state="done"] span {
          display: none; }
        .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items .kt-wizard-v3__nav-item[data-ktwizard-state="done"] i {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          background-color: #1dc9b7;
          color: #ffffff; }
    .kt-wizard-v3 .kt-wizard-v3__nav .kt-wizard-v3__nav-items.kt-wizard-v3__nav-items--clickable .kt-wizard-v3__nav-item {
      cursor: pointer; }

.kt-wizard-v3 .kt-form {
  padding: 2rem 4rem 4rem; }
  @media (max-width: 576px) {
    .kt-wizard-v3 .kt-form {
      padding: 2rem 1rem 4rem; } }
  .kt-wizard-v3 .kt-form .kt-wizard-v3__content {
    padding-bottom: 1.3rem;
    border-bottom: 1px solid #eeeef4;
    margin: 0 auto 2rem;
    width: 630px; }
    @media (max-width: 1399px) {
      .kt-wizard-v3 .kt-form .kt-wizard-v3__content {
        width: 100%;
        margin: 0 0 1.5rem 0; } }
  .kt-wizard-v3 .kt-form .kt-form__actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 630px;
    margin: 0 auto; }
    @media (max-width: 1399px) {
      .kt-wizard-v3 .kt-form .kt-form__actions {
        width: 100%;
        margin: 0; } }
    @media (max-width: 576px) {
      .kt-wizard-v3 .kt-form .kt-form__actions {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; } }
    @media (max-width: 576px) {
      .kt-wizard-v3 .kt-form .kt-form__actions .btn {
        margin: 0 0.5rem 1rem; } }
    .kt-wizard-v3 .kt-form .kt-form__actions [data-ktwizard-type="action-prev"] {
      margin-right: auto; }
      @media (max-width: 576px) {
        .kt-wizard-v3 .kt-form .kt-form__actions [data-ktwizard-type="action-prev"] {
          margin-right: 0.5rem; } }
    .kt-wizard-v3 .kt-form .kt-form__actions [data-ktwizard-type="action-next"] {
      margin: auto 0 auto auto; }
      @media (max-width: 576px) {
        .kt-wizard-v3 .kt-form .kt-form__actions [data-ktwizard-type="action-next"] {
          margin: 0 0 1rem; } }

.kt-wizard-v3 [data-ktwizard-type="step-info"] {
  display: none; }
  .kt-wizard-v3 [data-ktwizard-type="step-info"][data-ktwizard-state="current"] {
    display: block; }

.kt-wizard-v3 [data-ktwizard-type="step-content"] {
  display: none; }
  .kt-wizard-v3 [data-ktwizard-type="step-content"][data-ktwizard-state="current"] {
    display: block; }

.kt-wizard-v3 [data-ktwizard-type="action-prev"] {
  display: none; }

.kt-wizard-v3 [data-ktwizard-type="action-next"] {
  display: inline-block; }

.kt-wizard-v3 [data-ktwizard-type="action-submit"] {
  display: none; }

.kt-wizard-v3[data-ktwizard-state="first"] [data-ktwizard-type="action-prev"] {
  display: none; }

.kt-wizard-v3[data-ktwizard-state="first"] [data-ktwizard-type="action-next"] {
  display: inline-block; }

.kt-wizard-v3[data-ktwizard-state="first"] [data-ktwizard-type="action-submit"] {
  display: none; }

.kt-wizard-v3[data-ktwizard-state="between"] [data-ktwizard-type="action-prev"] {
  display: inline-block; }

.kt-wizard-v3[data-ktwizard-state="between"] [data-ktwizard-type="action-next"] {
  display: inline-block; }

.kt-wizard-v3[data-ktwizard-state="between"] [data-ktwizard-type="action-submit"] {
  display: none; }

.kt-wizard-v3[data-ktwizard-state="last"] [data-ktwizard-type="action-prev"] {
  display: inline-block; }

.kt-wizard-v3[data-ktwizard-state="last"] [data-ktwizard-type="action-next"] {
  display: none; }

.kt-wizard-v3[data-ktwizard-state="last"] [data-ktwizard-type="action-submit"] {
  display: inline-block; }

@media (min-width: 1400px) {
  .kt-wizard-v3 .kt-wizard-v3__aside {
    width: 400px;
    border-right: 1px solid #eeeef4;
    padding: 1.5rem; }
  .kt-wizard-v3 .kt-wizard-v3__wrapper {
    padding: 1.5rem; } }

@media (min-width: 1025px) and (max-width: 1399px) {
  .kt-wizard-v3 .kt-wizard-v3__aside {
    border-bottom: 1px solid #eeeef4;
    padding: 1rem; }
  .kt-wizard-v3 .kt-wizard-v3__wrapper {
    padding: 1rem; } }

@media (max-width: 1024px) {
  .kt-wizard-v3 .kt-wizard-v3__aside {
    padding: 1rem; }
  .kt-wizard-v3 .kt-wizard-v3__wrapper {
    padding: 1rem; } }
    .resend-otp-section {
      margin-top: 10px;
      display: flex;
      align-items: center;
  }
  
  .resend-otp-section {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.resend-otp-button {
    background-color: #ff0000;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.resend-otp-button i {
    margin-right: 5px;
}

.resend-otp-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.timer {
    margin-left: 10px;
    color: #ff0000;
    display: flex;
    align-items: center;
}

.timer i {
    margin-right: 5px;
}


