.headerCollapse
        {
            background-color: #fff !important;
            text-decoration: none !important;
        }
        .headerCollapse:hover
        {
            background-color: #fff !important;
            text-decoration: none !important;
        }

        .headerCollapse:active
        {
            background-color: #fff !important;
            text-decoration: none !important;
        }

        .bb0
        {
            /*border: 1px solid;*/
            border: 0 !important;
        }
		/*.customAccordian
        {
            border: 1px solid !important;;
            border-top: 0 !important;
        }*/
        .custom-plan-expanded
        {
            font-size: 10px;
            color:#C11D6C;
            margin-right: 5px;
        }

        .accordianCardBody p
        {

          margin-bottom: 4px !important;

        }

        .accordianCard
        {
            border: 0 !important;
        }

        .accordianCardBody
        {
            border-top: 1px solid;
            font-size: 16px;
            background-color:#fcf2f7;
        }

        .accordianCardHeader
        {
            padding-bottom: 15px !important;
        }

        .bonus
        {
            position: absolute;
            background-color:#C11D6C;
            color: #fff;
            z-index: 8;
            top: -11px;
            left: 35px;
            padding: 5px 8px;
            border-radius: 10px;
            font-weight: 900;
        }

        .planText1
        {
            margin-bottom: 0.2rem;
            font-size:15px;
        }
        .planText2
        {
            margin-top: 2rem;
            font-size: 16px;
        }
        .selectBtnWrapper
        {
            margin-top: 3rem;
        }
        .selectBtn
        {
            width: 100%;border: 1px solid;
        }
        .planCardHeader
        {
            background-color: #fff;
            border: 0;
        }
        .b0
        {
            border: 0 !important;
        }
		.plan_data{
			font-size: 1.8rem;
		} 

        
.kt-content{
    margin-top: -60px;
}
@media only screen and (max-width: 768px) {
    .kt-content{
        margin-top: 55px; 
    
    }
  }
  .kt-widget__links{
    width: 202px;
  }

  .kt-portlet .kt-portlet__foot {
    padding: 25px;
    padding-top: 0px !important;
    border-top: none !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
} 
.modal-lg {
    max-width: 800px !important;
}

input[type=text]:focus {
    border: 1px solid #FF0000;
  }
  input[type=email]:focus {
    border: 1px solid #FF0000;
  }
  input[type=password]:focus {
    border: 1px solid #FF0000;
  }
  input[type=radio]:focus {
    border: 1px solid #FF0000;
  }
  input[type=checkbox]:focus {
    border: 1px solid #FF0000;
  }
  .form-control:focus {
    border: 1px solid #FF0000;
  }
  .kt_selectpicker:focus {
    border: 1px solid #C11D6C;
  }
  .nav-pills .nav-item .nav-link.active, .nav-pills .nav-item .nav-link.active:hover {
     background-color: #C11D6C;
  }
  .bootstrap-select.show > .dropdown-toggle.btn-light, .bootstrap-select.show > .dropdown-toggle.btn-secondar {
    border: 1px solid #C11D6C;
  }
  .bootstrap-select > .dropdown-toggle.btn-light:focus, .bootstrap-select > .dropdown-toggle.btn-light.active, .bootstrap-select > .dropdown-toggle.btn-secondary:focus, .bootstrap-select > .dropdown-toggle.btn-secondary.active{
      border: 1px solid #C11D6C;
  }
  .payment_table_head{
    padding-top: 0.75rem;
    padding-right: 1.25rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
  }
  .payment_serial_no{
    padding-top: 1rem;
  }
  .payment_content{
    text-align: center;
    padding-top: 1rem;
  }
  .fontSize18{
    font-size:18px;
  }
  .accordianCardHeader{
    background-color: #fff;
  }
  .whatElseInclude{
    color:#C11D6C;
  }
  .headerCollapse{
    color:#C11D6C; 
    font-size: 16px;
  }
  .promo_label{
    text-align: justify;
    padding: 2px;
    margin: 1px;
  }
  .promo_div{
    border:1px solid;
    border-color: #C11D6C;
    border-radius: 10px;
  }
  .display_none{
    display: none;
  }
  #list_plan{
    display: none;
    margin-top: 2rem;
  } 

  .btn-brand {
	color: #fff !important;
	background-color: #C11D6C;
	border-color: #C11D6C;
	color: #ffffff; }
	.btn-brand:hover {
	  color: #fff;
	  background-color: #C11D6C;
	  border-color: #C11D6C; }
	.btn-brand:focus, .btn-brand.focus {
	  color: #fff;
	  background-color: #C11D6C;
	  border-color: #C11D6C;
	  -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 140, 255, 0.5);
	  box-shadow: 0 0 0 0.2rem rgba(117, 140, 255, 0.5); }
	.btn-brand.disabled, .btn-brand:disabled {
	  color: #fff;
	  background-color: #C11D6C;
	  border-color: #C11D6C; }
	.btn-brand:not(:disabled):not(.disabled):active, .btn-brand:not(:disabled):not(.disabled).active,
	.show > .btn-brand.dropdown-toggle {
	  color: #fff;
	  background-color: #C11D6C;
	  border-color: #C11D6C; }
	  .btn-brand:not(:disabled):not(.disabled):active:focus, .btn-brand:not(:disabled):not(.disabled).active:focus,
	  .show > .btn-brand.dropdown-toggle:focus {
		-webkit-box-shadow: 0 0 0 0.2rem rgba(117, 140, 255, 0.5);
		box-shadow: 0 0 0 0.2rem rgba(117, 140, 255, 0.5); }
	.btn-brand + .btn.dropdown-toggle {
	  position: relative; }
	  .btn-brand + .btn.dropdown-toggle:before {
		display: block;
		content: ' ';
		border-left: 1px solid #ffffff;
		position: absolute;
		top: 3px;
		left: -1px;
		bottom: 3px;
		opacity: 0.3; }
	  .btn-brand + .btn.dropdown-toggle:hover:before, .btn-brand + .btn.dropdown-toggle:focus:before, .btn-brand + .btn.dropdown-toggle.active:before {
		display: none; }

    .kt-portlet .kt-portlet__foot {
      padding: 25px;
      padding-top: 0px !important;
      border-top: none !important;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      } 
      .modal-lg {
      max-width: 800px !important;
      }

      input[type=text]:focus {
        border: 1px solid #C11D6C;
        }
        input[type=email]:focus {
        border: 1px solid #C11D6C;
        }
        input[type=password]:focus {
        border: 1px solid #C11D6C;
        }
        input[type=radio]:focus {
        border: 1px solid #C11D6C;
        }
        input[type=checkbox]:focus {
        border: 1px solid #C11D6C;
        }
        .form-control:focus {
        border: 1px solid #C11D6C;
        }
        .kt_selectpicker:focus {
        border: 1px solid #C11D6C;
        }
        .nav-pills .nav-item .nav-link.active,
        .nav-pills .nav-item .nav-link.active:hover {
        background-color: #C11D6C;
        }
        .bootstrap-select.show>.dropdown-toggle.btn-light,
        .bootstrap-select.show>.dropdown-toggle.btn-secondar {
        border: 1px solid #C11D6C;
        }
        .bootstrap-select>.dropdown-toggle.btn-light:focus,
        .bootstrap-select>.dropdown-toggle.btn-light.active,
        .bootstrap-select>.dropdown-toggle.btn-secondary:focus,
        .bootstrap-select>.dropdown-toggle.btn-secondary.active {
        border: 1px solid #C11D6C;
        }
        .modal-lg {
        max-width: 650px;
        }

        .nav-pills .nav-item .nav-link {
          background-color: #C11D6C;
          color: #ffffff;
      }
      .account_summary_head{
        color:#C11D6C !important;
      }

.usage_card{
  margin-top: 10px !important; 
}




.verfiytxt{position: absolute;
    bottom: -23px;
    margin-bottom: 0;
    color:#C11D6C;
    font-weight: 600;
    right: 0;}

#curr_addr_div input{display: block;
    width: 100%;
    padding: .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;border-radius: 3px 0 0 3px;padding-right: 65px;}

#curr_addr_div button{border-radius: 0 3px 3px 0;width: 60px;height: 100%;}
#curr_addr_div .input-group-append{position: absolute;right: 0;z-index: 99;top: 0;bottom: 0}

#curr_addr_div:focus {outline: none !important;}
#curr_addr_div input[type=text]:focus {border: 1px solid #C11D6C !important;outline: none !important;}
.txt-left{text-align: left !important;}
.txt-hide{visibility: hidden;}
.prep_icon span{display: block;height: 100%;padding:.75rem;}
.prep_icon span .icon_front{line-height: 100%;}
.common-class .form-control,
.common-class #verify_email,
.common-class #saveBtn{padding: .75rem;}



#activate-your-number .form-control{  padding: .75rem; }
#activate-your-number #show_password{  padding: .30rem; display: block; }



.mar-btm{margin-bottom:20px;}


.autorechargeUl {padding-left: 0;list-style: none;}
.autorechargeUl li {padding: 10px;background: #fcf2f7;margin-bottom: 5px;border-left: 5px solid #C11D6C;}
.autorechargeUl li .lispan{width: 100px;
    display: inline-block;
    padding-top: 4px;
    float: left;
    padding-left: 10px;;}






/* The switch - the box around the slider */
.telsimtoggle .switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

/* Hide default HTML checkbox */
.telsimtoggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.telsimtoggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.telsimtoggle .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.telsimtoggle input:checked + .slider {
  background-color: #C11D6C;
}

.telsimtoggle input:focus + .slider {
  box-shadow: 0 0 1px #C11D6C;
}

.telsimtoggle input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.telsimtoggle .slider.round {
  border-radius: 34px;
}

.telsimtoggle .slider.round:before {
  border-radius: 50%;
}

.df-btn{margin-left: 10px;position: relative;padding-right: 25px;margin-top: 6px;}
.df-btn input{position: absolute;right: 6px;top: 7px;filter: grayscale(1);}
.mar-btm{margin-bottom:20px; ;}

.date-card{
    margin-top: 15px;
    color:#C11D6C;
}


.pagination {
  display: flex;
  gap: 10px;
  list-style: none;
  cursor: pointer;
  margin-top: 30px;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #C11D6C;
  color: #C11D6C;
  padding: 8px 16px;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff !important;
  background: #C11D6C;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.pagination li {
  margin: 1px;
}





.common-layout .react-date-picker__wrapper{border: none !important;}
.common-layout .react-date-picker__inputGroup__input:invalid{background-color: transparent !important;}

.common-profile{padding-top: 20px;}
.common-profile .kt-portlet__head-title{background: #f6f7fd;font-size: 14px;padding: 10px;border-radius: 5px;}
.myprofilebox{margin-bottom: 30px;}
.myprofilebox .label_value{color: #000;}
.myprofilebox .label_value input{border-radius: 5px !important;}
.myprofilebox .label_value button{border-radius: 5px;}
.myprofilebox .label_value .myprofileboxgroup{width: 300px;}
.myprofilebox .label_edit{color: #C11D6C;cursor: pointer;}
.myprofilebox .label_edit span:hover{text-decoration: underline;font-weight: 500;}
.editprofile{color: #C11D6C;cursor: pointer;padding: 5px;}
.myprofileboxgroup .verify-input{width: 150px;float: left;}
.myprofileboxgroup #verify_email_cancel{float: left;padding: .75rem;}
.myprofileboxgroup #verify_email_btn{float: left;padding: .75rem;margin-right: 10px;}
.myprofileboxgroup .input-group-append{margin-left: 5px !important;}
#curr_addr_div button{border-radius: 5px;margin-top: 10px;width: 100px;padding: 0.75rem;}


.emailinputbox{width:400px}
@media(max-width:410px)
{
  .emailinputbox{width:400px}
}

.backendErrorMsg
{
  font-weight: bold !important;
  margin-bottom: 16px !important;
}




.oldsimform .form-group > div{margin-bottom: 25px;}
.oldsimform .oldhead{background: #f6f7fd;font-size: 14px;padding: 10px;border-radius: 5px;}
.oldsimform .form-control{width: 100%;}

.spcpadding{padding-left: 0;padding-right: 30px;}

.addressbox{position: relative;}
.clearaddress{position: absolute;
    right: 0;
    top: 0px;
    padding: 4px 10px;
    cursor: pointer;
    color: #C11D6C;
    font-size: 21px;
    font-weight: 400;z-index: 9;font-style: normal;}




.recharge .tab-content{padding-left: 0;padding-right: 0;}
.recharge .kt-portlet{box-shadow: none !important;}
.rechargebox {float: right;}
.rechargebox .kt_selectpicker{width: auto;}
.rechargebox .btn-brand{width: auto;margin-left: 15px;}


.m0
{
  margin:0 !important;
}

.p-y-1
{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.loader
{
  text-align: center;
  width: 100%;
  height: 190rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}
.loaderCenter
{
  position: fixed;
  top: 30%;
  left: 50%;
}
.swal-title {
  font-size: 16px;
}
.kt-footer {
  /* position: fixed; */
  bottom: 0px;
  width: 100%;
  
}

.IdValidation .customdate{ padding: 5px !important; }
#activate-your-number .customdate .react-date-picker{ padding: 5px !important; }
#activate-your-number .customdate1{ padding: 6px !important; }

#root{
  height:100% !important;
}

.kt-grid--root
{
  height: 100% !important;
}

.kt-invoice-1 .kt-invoice__body table thead tr th 
{
  background-color: transparent;
  padding: 1rem 1rem 0.5rem 0;
  border-top: none;
  color: #595d6e;
  border-bottom: 1px solid #eaebec !important;
  font-weight: 500; }

.kt-invoice-1 .kt-invoice__body table
{
  border-collapse: inherit !important;
}

tbody, td, tfoot, th, thead, tr
{
  border: 0 !important;
}



.common-class .saponlybox .form-control{ padding: .35rem;}
.common-class .saponlybox .form-group{ margin-bottom: 1rem;}
.common-class .saponlybox .sapuserdiv{ padding: 0;}
.addrselect{position: relative;}
.addrselect i{position: absolute;
    right: 0;
    top: 0px;
    padding: 4px 10px;
    cursor: pointer;
    color: #C11D6C;
    font-size: 14px;
    font-weight: 400;z-index: 9;font-style: normal;}




.plan-center
{
  margin: 0 auto !important;
}


.custom-select option { color: black !important; }
.custom-select:focus
{
  box-shadow: 0 0 0 0.25rem rgb(255 0 0 / 25%) !important;
}

.braintree-sheet__content--form label
{

  width: 100% !important;

}

.mb10
{
  margin-top: 10px !important;
}

.mb50
{
  margin-bottom: 50px !important;
}

.footer-border
{
  border-top: 1px solid #ebedf2 !important;
}

.mb60
{
  margin-bottom: 60px !important;
}

.mt10
{
  margin-top: 10px;
}

.ptb0
{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ptb10
{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.custom-signin
{
  float: right;
  margin: -22px 0 0 0 !important;
  padding: 6px 10px !important;
  font-size: 14px !important;
}




.rmvdftstyle .react-date-picker__wrapper{ border: none !important;  }
.rmvdftstyle .react-date-picker__wrapper input{ cursor: default;pointer-events: none;  }

.card {
  overflow: hidden; 
}
.ribbon {
  background: #F1D76D;
  color: #000;
  font-weight: 600;
  font-size: 0.9em;
  display: inline-block;
  padding: 45px 40px 10px;
  transform: rotate(45deg);
  position: absolute;
  top: -25px;
  right: -50px;
}
.center {
  text-align: center;
}
.esimView {
  margin-left: 10px;
}
.simImgView {
  margin-left: 5px;
}

@media(max-width:991px)
{
  .simView {
    margin: 0px;
    padding: 0;
    margin-top: 10px;
    width: 48%;
  }
  .simPadding {
    padding: 0;
    margin: 0;
  }
  .simleft {
    margin-left: 4%;
  }
  .simView1 {
    margin: 20px 1px 20px 1px;
  }
}
/*highlighting*/

.hasOffer{ border-color: #00c5dc!important; }
.hasOffer .plan_header{ background-color: #00c5dc !important; }
@media(max-width:991px)
{
  .hideView {
    display: none;
  }
}

.kt-radio > input:checked ~ span {
  border: 2px solid #C11D6C;
}
.kt-radio > span:after {
  border: solid #C11D6C;
  background: #C11D6C;
}






@media(min-width:991px){

    .middleBox{    
        width: 60%;
        margin: 0 auto;
        float: right;
    }

}



#gestMobileNumber:focus-visible{ border: 1px solid #dededf !important;  }


.tickgreen{    background: #8bc34a;
    font-size: 9px;
    width: 21px;
    height: 21px;
    display: inline-block;
    text-align: center;
    line-height: 21px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 2px;}

.tikmobile{font-size: 16px;
    position: relative;
    padding-left: 25px;}


.resetSpan{float: right;
    font-size: 12px;
    padding: 4px 8px;
    cursor: pointer;
    color: #C11D6C;
    text-decoration: underline;}


@media(min-width: 1200px){
  #planboxwidth{ width: 1020px; margin: 0 auto; }

  .guestPlan .mb50{margin-bottom: 0 !important;}
  .guestPlan .tab-content{padding-top: 50px !important; border: 1px solid #dee2e6; border-top: 0;}
  .guestPlan .tab-content .kt-portlet{ box-shadow: none !important; }
}



.clearfix::after {
  content: "";
  clear: both;
  display: table;
}


@media(min-width: 600px){
 #voucherButtons{width: 500px; margin: 0 auto;}
 #paymentBox{ width: 500px;margin:0 auto; }
 #paymentBox .kt-invoice__container{ width: 100%;margin:0 auto; padding: 0;}
}




.resendButtonStyle{background: #e9e9e9 !important; border-color: #e9e9e9 !important; color: #cdcbcb !important;}


.destinationsCl p{
    display: inline-block;
    background: #C11D6C;
    padding: 5px 8px;
    margin-right: 5px;
    font-size: 13px;
}



.IrBlock h5{
  font-size: 14px;
}






#accSummary .kt-widget__links{justify-content:left !important;}
